import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { ToasterContainer } from 'baseui/toast'
import 'parkdepot-shared/fonts/pkd/index.css'
import { ThemeProvider } from 'parkdepot-shared/theme'
import concatProviders from 'parkdepot-shared/utils/concatProviders'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import apolloClient from './apollo-client'
import './i18n'
import './index.css'

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
// https://vitejs.dev/guide/env-and-mode
// We are not relying on MODE (we use another variable - keep that in mind)
// For instance, VITE_MODE could be staging and MODE could be production
// We are building production mode to deploy to staging
const ENV = import.meta.env.VITE_MODE

if (SENTRY_DSN && ['staging', 'production'].includes(ENV)) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENV,
        attachStacktrace: true
    })
}

const RootProvider = concatProviders([
    {
        Component: BrowserRouter
    },
    {
        Component: ThemeProvider
    },
    {
        Component: ApolloProvider,
        props: { client: apolloClient }
    },
    {
        Component: ToasterContainer
    }
])

ReactDOM.render(
    <React.StrictMode>
        <RootProvider>
            <App />
        </RootProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
