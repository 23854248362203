import RoundedInput from '@components/RoundedInput'
import { Input as BUInput } from 'baseui/input'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_SHAPE, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { styled, useStyletron } from 'parkdepot-shared/theme'
import React, { ComponentProps, useEffect, useRef } from 'react'

type SearchInputProps = ComponentProps<typeof BUInput> & {
    button?: ComponentProps<typeof Button>
}

const Container = styled('div', () => ({
    display: 'flex',
    position: 'relative'
}))

const SearchInput = ({ value, button, ...props }: SearchInputProps) => {
    const [css, theme] = useStyletron()
    const ref = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (!ref.current) return

        ref.current.focus()
    }, [])

    return (
        <Container>
            <RoundedInput inputRef={ref} placeholder="MPD1234" value={value} {...props} />
            <Button
                $shape={BUTTON_SHAPE.circle}
                $size={BUTTON_SIZE.large}
                overrides={{
                    BaseButton: {
                        style: {
                            border: 'none',
                            position: 'absolute',
                            right: 0,
                            height: theme.sizing.scale1600,
                            width: theme.sizing.scale1600
                        }
                    }
                }}
                disabled={!value}
                {...button}
            >
                <i className={`pkd-search ${css({ fontSize: theme.sizing.scale800 })}`} />
            </Button>
        </Container>
    )
}

export default SearchInput
