import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import * as Sentry from '@sentry/react'
import useAppStore from '@store/app'
import errorMapper from '@utils/errors'
import { toaster } from 'baseui/toast'
import { GraphQLError } from 'graphql'

const generateApolloClient = () => {
    const httpLink = new HttpLink({
        uri: import.meta.env.VITE_HASURA_URL
    })

    const retryLink = new RetryLink()

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                'content-type': 'application/json'
            }
        }
    })

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
            const plate = useAppStore.getState().licensePlate
            const logGraphqlError = ({ message }: GraphQLError) =>
                Sentry.captureException(message, { extra: { plate, operationName: operation.operationName } })

            graphQLErrors.map(logGraphqlError)
            if (operation.getContext().errorHandledLocally) return // Check this into the queries performed throughout the app

            toaster.negative(errorMapper.DEFAULT, { closeable: true, autoHideDuration: 8000 })
        }

        if (networkError) {
            toaster.negative(errorMapper.CHECK_INTERNET_CONNECTION, { closeable: true, autoHideDuration: 8000 })
            Sentry.captureException(networkError)
        }
    })

    const link = ApolloLink.from([errorLink, retryLink, authLink, httpLink])

    const cache = new InMemoryCache()

    return new ApolloClient({
        cache,
        link,
        connectToDevTools: !import.meta.env.PROD
    })
}

const apolloClient = generateApolloClient()

export default apolloClient
