import * as Sentry from '@sentry/react'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SHAPE, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { useStyletron } from 'parkdepot-shared/theme'
import React, { ReactNode } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface GlobalErrorBoundaryProps {
    children: ReactNode
}

const Fallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    const [css, theme] = useStyletron()
    const { t } = useTranslation('error')

    return (
        <div role="alert" className={css({ display: 'flex', flexDirection: 'column', gap: theme.sizing.scale800 })}>
            <p>{t('somethingWentWrong')}:</p>
            <pre style={{ color: theme.colors.negative }}>{error.message}</pre>
            <Button
                $shape={BUTTON_SHAPE.pill}
                $kind={BUTTON_KIND.primary}
                $size={BUTTON_SIZE.medium}
                onClick={resetErrorBoundary}
            >
                {t('goMainPage')}
            </Button>
        </div>
    )
}

const GlobalErrorBoundary = ({ children }: GlobalErrorBoundaryProps) => {
    const history = useHistory()

    const logError = (error: Error, info: { componentStack: string }) => {
        Sentry.captureException(error.message, { extra: { stack: info.componentStack } })
    }

    return (
        <ErrorBoundary FallbackComponent={Fallback} onError={logError} onReset={() => history.replace('/')}>
            {children}
        </ErrorBoundary>
    )
}

export default GlobalErrorBoundary
