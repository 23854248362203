import i18n from '@i18n'

export type ErrorKeys =
    | 'SITE_NOT_FOUND_ERROR_MESSAGE'
    | 'CHECK_INTERNET_CONNECTION'
    | 'DEFAULT'
    | 'SMS_NOT_SENT_ERROR_MESSAGE'

// Map next errors from the backend
const errorMapper: { [key in ErrorKeys]: string } = {
    SITE_NOT_FOUND_ERROR_MESSAGE: i18n.t('siteNotFound', { ns: 'serverErrors' }),
    CHECK_INTERNET_CONNECTION: i18n.t('checkInternetConnection', { ns: 'serverErrors' }),
    SMS_NOT_SENT_ERROR_MESSAGE: i18n.t('smsNotSent', { ns: 'serverErrors' }),
    DEFAULT: i18n.t('default', { ns: 'serverErrors' })
}

export default errorMapper
