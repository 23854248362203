import { QueryHookOptions, useQuery } from '@apollo/client'
import GET_PIN from '@graphql/queries/getPin.query'
import { GetAccessControlPinQuery, GetAccessControlPinQueryVariables } from 'parkdepot-shared/gql/graphql'

const useGetPin = ({ ...props }: QueryHookOptions<GetAccessControlPinQuery, GetAccessControlPinQueryVariables>) => {
    return useQuery(GET_PIN, {
        fetchPolicy: 'cache-and-network',
        ...props
    })
}

export default useGetPin
