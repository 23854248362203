import { graphql } from 'parkdepot-shared/gql'

const SEND_SMS_PIN = graphql(`
    query SendAccessControlSMSPin($plate: String!, $site_id: String!, $phone_number: String!) {
        carpay_access_control_send_pin_sms(plate: $plate, site_id: $site_id, phone_number: $phone_number) {
            sent
        }
    }
`)

export default SEND_SMS_PIN
