import i18n, { Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Settings } from 'luxon'
import { initReactI18next } from 'react-i18next'
import de from './de.json'
import de_CH from './de_CH.json'
import en from './en.json'
import en_CH from './en_CH.json'
import fr from './fr.json'
import fr_CH from './fr_CH.json'
import it from './it.json'
import it_CH from './it_CH.json'

function getLang() {
    if (typeof navigator.languages !== 'undefined') return navigator.languages[0]
    return navigator.language
}

const resources: Resource = {
    en,
    'en-CH': en_CH,
    de,
    'de-DE': de,
    'de-CH': de_CH,
    fr,
    'fr-CH': fr_CH,
    it,
    'it-CH': it_CH
}

// TODO: Check correct translations
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        detection: { order: ['navigator'] },
        ns: ['common', 'footer'],
        defaultNS: 'common',
        nsSeparator: '.',
        fallbackLng: {
            default: ['de-DE']
        },
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false
        },
        returnObjects: true
    })

Settings.defaultLocale = getLang()

export default i18n
export * from 'react-i18next'
