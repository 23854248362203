import { Input as BUInput, StyledInput, StyledRoot } from 'baseui/input'
import { withStyle } from 'parkdepot-shared/theme'
import React, { ComponentProps } from 'react'

type RoundedInputProps = ComponentProps<typeof BUInput>

const InputRoot = withStyle(StyledRoot, () => ({
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomLeftRadius: '50px',
    borderTopLeftRadius: '50px',
    borderBottomRightRadius: '50px',
    borderTopRightRadius: '50px'
}))

const Input = withStyle(StyledInput, ({ $theme }) => ({
    paddingTop: $theme.sizing.scale650,
    paddingBottom: $theme.sizing.scale650,
    paddingLeft: $theme.sizing.scale800,
    color: $theme.colors.black,
    ...$theme.typography.LabelLarge,
    backgroundColor: '#0000001A',

    '::placeholder': {
        color: $theme.colors.mono300
    }
}))

const RoundedInput = ({ ...props }: RoundedInputProps) => {
    return <BUInput overrides={{ Root: InputRoot, Input }} {...props} />
}

export default RoundedInput
