import { QueryHookOptions, useLazyQuery } from '@apollo/client'
import SEND_SMS_PIN from '@graphql/queries/sendSmsPin.query'
import { SendAccessControlSmsPinQuery, SendAccessControlSmsPinQueryVariables } from 'parkdepot-shared/gql/graphql'

const useSendPin = ({
    ...props
}: QueryHookOptions<SendAccessControlSmsPinQuery, SendAccessControlSmsPinQueryVariables>) => {
    return useLazyQuery(SEND_SMS_PIN, {
        fetchPolicy: 'cache-and-network',
        ...props
    })
}

export default useSendPin
