import { DateTime } from 'luxon'

const getFormattedHour = (date: string): string => {
    const formattedDate = DateTime.fromISO(date)

    if (!formattedDate.isValid) return 'Wrong Date'
    return formattedDate.toFormat('HH:mm')
}

const getFormattedDate = (date: string): string => {
    const formattedDate = DateTime.fromISO(date)

    if (!formattedDate.isValid) return 'Wrong Date'
    return formattedDate.toLocaleString()
}

export { getFormattedDate, getFormattedHour }
