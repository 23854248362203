import GlobalErrorBoundary from '@components/GlobalErrorBoundary'
import Header from '@components/Header'
import { Footer } from 'parkdepot-shared/components/Footer'
import { LanguageSelectOptions } from 'parkdepot-shared/components/Select'
import { styled } from 'parkdepot-shared/theme'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

const languages = LanguageSelectOptions.filter((lang) => {
    return lang.id !== 'pl' && lang.id !== 'da'
})

const AppFrame = styled('div', () => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
}))

const MainViewFrame = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    flex: 1,
    maxWidth: '480px',
    padding: `${$theme.sizing.scale1200} 31px 0 ${$theme.sizing.scale900}`,

    [$theme.mediaQuery.small]: {
        minHeight: '100vh'
    }
}))

// We want to render the error boundary context inside the MainViewFrame
const Frame = ({ children }: { children: ReactNode }) => {
    const { t, i18n } = useTranslation('footer')

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang)
    }

    const footerLinks = [
        {
            label: t('footer.impressum'),
            clicked: () => window.open('https://www.wemolo.com/impressum', '_blank')
        },
        {
            label: t('footer.dataPrivacy'),
            clicked: () => window.open('https://www.wemolo.com/datenschutz', '_blank')
        }
    ]

    return (
        <AppFrame>
            <Header />
            <MainViewFrame>
                <GlobalErrorBoundary>{children}</GlobalErrorBoundary>
            </MainViewFrame>
            <Footer
                selectedLanguage={i18n.language}
                languages={languages}
                onLanguageChange={handleLanguageChange}
                links={footerLinks}
            />
        </AppFrame>
    )
}

export default Frame
