import PageTitle from '@components/PageTitle'
import useParamValidator from '@hooks/useParamValidator'
import { useTranslation } from '@i18n'
import useAppStore from '@store/app'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import LicensePlateInput from 'parkdepot-shared/components/LPInput'
import { styled } from 'parkdepot-shared/theme'
import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'

const PageContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale1400
}))

const LPContainer = styled('div', () => ({
    width: '189px',
    alignSelf: 'center'
}))

const DescriptionWrapper = styled('div', ({ $theme }) => ({
    marginBottom: 0,
    color: $theme.colors.black
}))

const DescriptionTitle = styled('div', () => ({
    fontWeight: 600
}))

const DescriptionList = styled('ul', ({ $theme }) => ({
    listStylePosition: 'inside',
    paddingInlineStart: $theme.sizing.scale100,
    textAlign: 'left'
}))

const ButtonsWrapper = styled('div', ({ $theme }) => ({
    display: 'flex',
    gap: $theme.sizing.scale500,
    justifyContent: 'center'
}))

const MainContentWrapper = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: $theme.sizing.scale900
}))

const LicensePlateNotFound = () => {
    const siteId = useParamValidator()
    const licensePlate = useAppStore((state) => state.licensePlate)
    const history = useHistory()
    const { t } = useTranslation('licensePlateNotFoundPage')

    const handleLPCorrect = () => {
        history.push(`/${siteId}`)
    }

    const handleConfirm = () => {
        history.push(`/${siteId}/mobile-number`)
    }

    if (!licensePlate) {
        return <Redirect to={`/${siteId}`} />
    }

    return (
        <PageContainer>
            <PageTitle>{t('title')}</PageTitle>

            <MainContentWrapper>
                <LPContainer>
                    <LicensePlateInput readOnly value={licensePlate} />
                </LPContainer>

                <DescriptionWrapper>
                    <DescriptionTitle>{t('descriptionTitle')}</DescriptionTitle>
                    <DescriptionList>
                        <li>{t('reason1')}</li>
                        <li>{t('reason2')}</li>
                        <li>{t('reason3')}</li>
                        <li>{t('reason5')}</li>
                    </DescriptionList>
                </DescriptionWrapper>
            </MainContentWrapper>

            <ButtonsWrapper>
                <Button
                    overrides={{ BaseButton: { style: { flex: 1 } } }}
                    $kind={BUTTON_KIND.primary}
                    shape="pill"
                    $size={BUTTON_SIZE.medium}
                    onClick={handleLPCorrect}
                >
                    {t('correct')}
                </Button>
                <Button
                    overrides={{ BaseButton: { style: { flex: 1 } } }}
                    $kind={BUTTON_KIND.secondary}
                    $size={BUTTON_SIZE.medium}
                    shape="pill"
                    onClick={handleConfirm}
                >
                    {t('confirm')}
                </Button>
            </ButtonsWrapper>
        </PageContainer>
    )
}

export default LicensePlateNotFound
