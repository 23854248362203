import MobileNumberInput from '@components/MobileNumberInput'
import PageTitle from '@components/PageTitle'
import useParamValidator from '@hooks/useParamValidator'
import useSendPin from '@hooks/useSendPin'
import { useTranslation } from '@i18n'
import useAppStore from '@store/app'
import errorMapper, { ErrorKeys } from '@utils/errors'
import { FormControl } from 'baseui/form-control'
import { COUNTRIES } from 'baseui/phone-input'
import { toaster } from 'baseui/toast'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SHAPE, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { styled } from 'parkdepot-shared/theme'
import React, { ChangeEvent, FormEvent, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

const isValidPhoneNumber = (number: string) => {
    // just validate numbers only for now
    const regExp = new RegExp('^\\d+$')
    return regExp.test(number)
}

const PageContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale1400
}))

const Form = styled('form', () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'stretch'
}))

type CountryKeys = keyof typeof COUNTRIES
type CountryValues = (typeof COUNTRIES)[CountryKeys]

const MobileNumber = () => {
    const siteId = useParamValidator()
    const history = useHistory()
    const licensePlate = useAppStore((state) => state.licensePlate)
    const { t } = useTranslation('mobileNumberPage')
    const [country, setCountry] = useState<CountryValues>(COUNTRIES.DE)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [inputError, setInputError] = useState(false)
    const [isVisited, setIsVisited] = useState(false)

    const [sendSMSPin, { loading }] = useSendPin({
        context: { errorHandledLocally: true }, // We don't want to handle this error globally
        onCompleted: () => {
            history.push(`/${siteId}/sms`)
        },
        onError: (error) => {
            history.push('/')

            const errorMessage = errorMapper[error.message as ErrorKeys]
            if (!errorMessage) return

            toaster.negative(errorMessage, {
                closeable: true,
                autoHideDuration: 8000
            })
        }
    })

    const shouldShowError = !inputError && isVisited

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputError(isValidPhoneNumber(e.currentTarget.value))
        setPhoneNumber(e.currentTarget.value)
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()

        sendSMSPin({
            variables: { plate: licensePlate, site_id: siteId, phone_number: country.dialCode + phoneNumber }
        })
    }

    if (!licensePlate) {
        return <Redirect to={`/${siteId}`} />
    }

    return (
        <PageContainer>
            <PageTitle>{t('title')}</PageTitle>

            <Form onSubmit={handleSubmit}>
                <FormControl error={shouldShowError ? 'Error' : undefined}>
                    {/* It's not working because baseui weirderness */}
                    {/* @ts-ignore */}
                    <MobileNumberInput
                        required
                        placeholder="(0) 000 0000 000"
                        onBlur={() => setIsVisited(true)}
                        country={country}
                        onCountryChange={({ option }) => setCountry(option as CountryValues)}
                        text={phoneNumber}
                        onTextChange={handleInputChange}
                        error={shouldShowError}
                    />
                </FormControl>

                <Button
                    $shape={BUTTON_SHAPE.pill}
                    $kind={BUTTON_KIND.primary}
                    $size={BUTTON_SIZE.medium}
                    disabled={!phoneNumber || shouldShowError}
                    type="submit"
                    isLoading={loading}
                >
                    {t('requestPin')}
                </Button>
            </Form>
        </PageContainer>
    )
}

export default MobileNumber
