/* eslint-disable no-nested-ternary */
import { StyledInput, StyledInputContainer, StyledRoot } from 'baseui/input'
import { PhoneInput, PhoneInputProps } from 'baseui/phone-input'
import { SharedStylePropsArg, Value } from 'baseui/select'
import { ExtendedTheme, withStyle } from 'parkdepot-shared/theme'
import React from 'react'

const InputRoot = withStyle(StyledRoot, ({ $isFocused, $error, $theme }) => ({
    backgroundColor: $theme.colors.white,
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    borderBottomWidth: '1px',
    borderBottomColor: $error ? $theme.colors.negative : !$isFocused ? $theme.colors.accent300 : 'inherit',
    borderLeftColor: $theme.colors.white,
    borderRightColor: $theme.colors.white,
    borderTopColor: $theme.colors.white
}))

const Input = withStyle(StyledInput, ({ $theme }) => ({
    backgroundColor: $theme.colors.white
}))

const InputContainer = withStyle(StyledInputContainer, ({ $theme }) => ({
    backgroundColor: $theme.colors.white
}))

interface MobileNumberInputProps extends PhoneInputProps {
    onBlur: () => void
}

const MobileNumberInput = ({ onBlur, ...props }: MobileNumberInputProps) => {
    return (
        <PhoneInput
            {...props}
            overrides={{
                Input: {
                    props: {
                        onBlur,
                        overrides: {
                            Root: InputRoot,
                            Input,
                            InputContainer
                        }
                    }
                },
                CountrySelect: {
                    props: {
                        searchable: true,
                        ignoraCase: true,
                        filterOptions: (options: Value, filterValue: string) => {
                            const regx = new RegExp(filterValue.toLowerCase(), 'gi')
                            const reverseRegx = new RegExp(filterValue.toLowerCase().split('').reverse().join(''), 'gi')
                            const filerOpts = options.filter((option) => {
                                if (typeof option.label !== 'string') return false
                                return regx.test(option.label) || reverseRegx.test(option.label)
                            })

                            return filerOpts
                        },
                        overrides: {
                            // This does not work with withStyle
                            ControlContainer: {
                                style: ({
                                    $isFocused,
                                    $error,
                                    $theme
                                }: SharedStylePropsArg & { $theme: ExtendedTheme }) => ({
                                    backgroundColor: $theme.colors.white,
                                    borderBottomLeftRadius: '0',
                                    borderBottomRightRadius: '0',
                                    borderBottomWidth: '1px',
                                    borderBottomColor: $error
                                        ? $theme.colors.negative
                                        : !$isFocused
                                        ? $theme.colors.accent300
                                        : 'inherit',
                                    borderLeftColor: $theme.colors.white,
                                    borderRightColor: $theme.colors.white,
                                    borderTopColor: $theme.colors.white
                                })
                            }
                        }
                    }
                }
            }}
        />
    )
}

export default MobileNumberInput
