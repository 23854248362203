import { graphql } from 'parkdepot-shared/gql'

const GET_PIN = graphql(`
    query GetAccessControlPin($plate: String!, $site_id: String!) {
        carpay_access_control_get_pin(plate: $plate, site_id: $site_id) {
            pin
            valid_until
        }
    }
`)

export default GET_PIN
