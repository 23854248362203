import { styled } from 'parkdepot-shared/theme'
import React, { ReactNode } from 'react'

const StyledTitle = styled('h1', ({ $theme }) => ({
    ...$theme.typography.HeadingLarge,
    textAlign: 'center'
}))

const PageTitle = ({ children }: { children: ReactNode }) => {
    return <StyledTitle>{children}</StyledTitle>
}

export default PageTitle
