import PageTitle from '@components/PageTitle'
import Pin from '@components/Pin'
import useGetPin from '@hooks/useGetPin'
import useParamValidator from '@hooks/useParamValidator'
import { useTranslation } from '@i18n'
import useAppStore from '@store/app'
import { getFormattedDate, getFormattedHour } from '@utils/dates'
import errorMapper, { ErrorKeys } from '@utils/errors'
import { Spinner } from 'baseui/spinner'
import { toaster } from 'baseui/toast'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SHAPE, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { styled, useStyletron } from 'parkdepot-shared/theme'
import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'

const PageContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale1400
}))

const MainContentContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale900,
    alignItems: 'center'
}))

const MainInfoContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale600,
    alignItems: 'center'
}))

const PinValidity = styled('p', ({ $theme }) => ({
    ...$theme.typography.LabelXSmall,
    textAlign: 'center',
    color: $theme.colors.primary
}))

const PinExplanation = styled('p', ({ $theme }) => ({
    ...$theme.typography.ParagraphMedium,
    textAlign: 'center'
}))

const DoorPin = () => {
    const siteId = useParamValidator()
    const [, theme] = useStyletron()
    const { t } = useTranslation(['doorPinPage', 'common'])
    const licensePlate = useAppStore((state) => state.licensePlate)
    const clearStore = useAppStore((state) => state.cleanState)
    const history = useHistory()

    const { data, loading } = useGetPin({
        variables: { site_id: siteId, plate: licensePlate },
        context: { errorHandledLocally: true }, // We don't want to handle this error globally
        onError: (error) => {
            history.push('/')

            const errorMessage = errorMapper[error.message as ErrorKeys]
            if (!errorMessage) return

            toaster.negative(errorMessage, {
                closeable: true,
                autoHideDuration: 8000
            })
        }
    })

    const handleExit = () => {
        clearStore()

        history.push('/')
    }

    if (!licensePlate) {
        return <Redirect to={`/${siteId}`} />
    }

    if (data && !data.carpay_access_control_get_pin.pin) {
        // If no pin -> you go to the error flow
        return <Redirect to={`/${siteId}/not-found`} />
    }

    const hour = getFormattedHour(data?.carpay_access_control_get_pin.valid_until ?? '')
    const date = getFormattedDate(data?.carpay_access_control_get_pin.valid_until ?? '')
    const pin = data?.carpay_access_control_get_pin.pin

    return (
        <PageContainer>
            <PageTitle>{t('title')}</PageTitle>
            <MainContentContainer>
                {!loading ? (
                    <>
                        <MainInfoContainer>
                            <Pin>{pin}</Pin>
                            <PinValidity>{t('pinValidity', { hour, date })}</PinValidity>
                        </MainInfoContainer>
                        <PinExplanation>{t('explanation')}</PinExplanation>
                    </>
                ) : (
                    <Spinner $color={theme.colors.primary} />
                )}
            </MainContentContainer>

            <Button
                $shape={BUTTON_SHAPE.pill}
                $kind={BUTTON_KIND.primary}
                $size={BUTTON_SIZE.medium}
                onClick={handleExit}
            >
                {t('common.exit')}
            </Button>
        </PageContainer>
    )
}

export default DoorPin
