import PageTitle from '@components/PageTitle'
import SearchInput from '@components/SeachInput'
import { useTranslation } from '@i18n'
import { styled } from 'parkdepot-shared/theme'
import React, { ChangeEvent, FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'

const PageContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale1400
}))

const SiteId = () => {
    const [siteId, setSiteId] = useState('')
    const { t } = useTranslation('siteIdPage')
    const history = useHistory()

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSiteId(e.currentTarget.value)
    }

    const handleClick = (e: FormEvent) => {
        e.preventDefault()

        history.push(`/${siteId}`)
    }

    return (
        <PageContainer>
            <PageTitle>{t('title')}</PageTitle>

            <form onSubmit={handleClick}>
                <SearchInput
                    value={siteId}
                    button={{ type: 'submit' }}
                    onChange={handleInputChange}
                    placeholder="1234"
                    type="number"
                />
            </form>
        </PageContainer>
    )
}

export default SiteId
