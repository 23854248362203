import { styled } from 'parkdepot-shared/theme'
import React, { ReactNode } from 'react'

const PinContainer = styled('h1', ({ $theme }) => ({
    ...$theme.typography.DisplaySmall,
    color: $theme.colors.primary,
    letterSpacing: $theme.sizing.scale600,
    textIndent: `-${$theme.sizing.scale600}`, // to get rid of the spacing of the last letter
    direction: 'rtl' // to get rid of the spacing of the last letter
}))

const Pin = ({ children }: { children: ReactNode }) => {
    return <PinContainer>{children}</PinContainer>
}

export default Pin
