import PageTitle from '@components/PageTitle'
import useParamValidator from '@hooks/useParamValidator'
import { useTranslation } from '@i18n'
import useAppStore from '@store/app'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SHAPE, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { styled } from 'parkdepot-shared/theme'
import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'

const PageContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale1400
}))

const Explanation = styled('p', ({ $theme }) => ({
    ...$theme.typography.ParagraphMedium,
    textAlign: 'center'
}))

const Sms = () => {
    const siteId = useParamValidator()
    const { t } = useTranslation(['smsPage', 'common'])
    const licensePlate = useAppStore((state) => state.licensePlate)
    const clearStore = useAppStore((state) => state.cleanState)
    const history = useHistory()

    const handleClick = () => {
        clearStore()

        history.replace(`/${siteId}`)
    }

    if (!licensePlate) return <Redirect to={`/${siteId}`} />

    return (
        <PageContainer>
            <PageTitle>{t('title')}</PageTitle>

            <Explanation>{t('subtitle')}</Explanation>

            <Button
                $shape={BUTTON_SHAPE.pill}
                $kind={BUTTON_KIND.primary}
                $size={BUTTON_SIZE.medium}
                onClick={handleClick}
            >
                {t('common.exit')}
            </Button>
        </PageContainer>
    )
}

export default Sms
