import PageTitle from '@components/PageTitle'
import SearchInput from '@components/SeachInput'
import { useTranslation } from '@i18n'
import useAppStore from '@store/app'
import { styled } from 'parkdepot-shared/theme'
import React, { ChangeEvent, FormEvent } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const PageContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale1400
}))

const Entry = () => {
    const { t } = useTranslation('entryPage')
    const history = useHistory()
    const location = useLocation()
    const licensePlate = useAppStore((state) => state.licensePlate)
    const setLicensePlate = useAppStore((state) => state.setLicensePlate)

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const input = e.currentTarget.value.replace(/[^a-zA-Z0-9äÄöÖüÜ]/g, '').toLocaleUpperCase()

        setLicensePlate(input)
    }

    const handleClick = (e: FormEvent) => {
        e.preventDefault()

        history.push(`${location.pathname}/pin`)
    }

    return (
        <PageContainer>
            <PageTitle>{t('title')}</PageTitle>

            <form onSubmit={handleClick}>
                <SearchInput
                    value={licensePlate}
                    onChange={handleInputChange}
                    button={{ type: 'submit' }}
                    maxLength={10}
                />
            </form>
        </PageContainer>
    )
}

export default Entry
