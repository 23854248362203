import { useParams } from 'react-router-dom'

const useParamValidator = (): string => {
    const { siteId } = useParams<{ siteId?: string }>()

    if (!siteId) {
        // Shouldn't happen
        throw new Error('SiteId is not defined')
    }

    return siteId
}

export default useParamValidator
